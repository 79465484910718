// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-granite-countertops-index-js": () => import("./../../../src/pages/granite-countertops/index.js" /* webpackChunkName: "component---src-pages-granite-countertops-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marble-countertops-index-js": () => import("./../../../src/pages/marble-countertops/index.js" /* webpackChunkName: "component---src-pages-marble-countertops-index-js" */),
  "component---src-pages-quartz-countertops-index-js": () => import("./../../../src/pages/quartz-countertops/index.js" /* webpackChunkName: "component---src-pages-quartz-countertops-index-js" */),
  "component---src-templates-slab-detail-page-template-js": () => import("./../../../src/templates/SlabDetailPageTemplate.js" /* webpackChunkName: "component---src-templates-slab-detail-page-template-js" */)
}

